@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'), url(assets/fonts/Roboto-Light.ttf) format('truetype');
    font-display: swap;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(assets/fonts/Roboto-Regular.ttf) format('truetype');
    font-display: swap;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Medium'), local('Roboto-Medium'), url(assets/fonts/Roboto-Medium.ttf) format('truetype');
    font-display: swap;
}
